<template>
	<b-card-code>
		<validation-observer
                ref="refFormObserver"
		>
			<b-row>
				<b-col cols-12>
					<b-form >
						<b-row>
							<b-col
								cols="12"
							>
								<h5 class="mb-0">
									Nuevo examen del módulo
								</h5>
								<small class="text-muted">
									Ingrese los detalles del examen
								</small>
								<hr>
							</b-col>               
						</b-row>    
						<b-row>						
							<b-col md="12">
								<h2 class="mb-2">
									Información del Examen
								</h2>							
							</b-col>
							
							<b-col md="12" class="mb-2">
								<b-row>
									<b-col md="4">
										<validation-provider
												#default="{ errors }"
												name="Titulo"
												rules="required"
										>
											<b-form-group
												label="Título"
												label-for="nameExam"
											>   
												<b-input-group>
													<b-input-group-prepend>
														<b-button variant="outline-primary">
															<feather-icon icon="FileTextIcon" />
														</b-button>
													</b-input-group-prepend>
													<b-form-input
														id="nameExam"
														type="text"
														v-model="title"
														placeholder="Ingrese título del examen"												
														:state="errors.length > 0 ? false:null"
														trim
													/>
												</b-input-group>    
												<small class="text-danger">{{ errors[0] }}</small>                                         
											</b-form-group>									
										</validation-provider>
									</b-col>
									<b-col md="4">
										<validation-provider
												#default="{ errors }"
												name="Puntaje Total"
												rules="required|min:0"
										>
											<b-form-group
												label="Puntaje Total"
												label-for="scoreTotal"
											>   
												<b-input-group>
													<b-input-group-prepend>
														<b-button variant="outline-primary">
															<feather-icon icon="AwardIcon" />
														</b-button>
													</b-input-group-prepend>
													<b-form-input
														id="scoreTotal"
														type="number"
														v-model="scoreTotal"														
														:state="errors.length > 0 ? false:null"
														disabled
													/>
													<!-- :placeholder="`${scoreTotal} / 20`" -->
												</b-input-group>                                             
												<small class="text-danger">{{ errors[0] }}</small>                                         
											</b-form-group>									
										</validation-provider>								
									</b-col>
									<b-col md="4">
										<validation-provider
												#default="{ errors }"
												name="Duración"
												rules="required|min:0"
										>
											<b-form-group
												label="Duración (min)"
												label-for="timeExam"
											>   
												<b-input-group>
													<b-input-group-prepend>
														<b-button variant="outline-primary">
															<feather-icon icon="ClockIcon" />
														</b-button>
													</b-input-group-prepend>
													<b-form-input
														id="timeExam"
														type="number"
														v-model="time"
														placeholder="Ingrese la duración del examen en minutos"
														:state="errors.length > 0 ? false:null"
														trim
													/>
												</b-input-group>                                             
												<small class="text-danger">{{ errors[0] }}</small>                                         
											</b-form-group>									
										</validation-provider>									
									</b-col>
								</b-row>
							</b-col>
								
							<!-- Banco de preguntas -->
							<b-col md="12">
								<h6 class="text-primary font-weight-bold mb-2">
									Banco de Preguntas
								</h6>
									<!-- Searchbar -->
									<div class="d-flex align-content-center justify-content-between w-100 mb-2">
									<b-input-group class="input-group-merge">
										<b-input-group-prepend is-text>
											<feather-icon
												icon="SearchIcon"
												class="text-muted"
											/>
										</b-input-group-prepend>
										<b-form-input
											placeholder="Buscar pregunta"
											v-model="buscarPregunta"
											@change="searchQuestion"
										/>
									</b-input-group>
								</div>
								<perfect-scrollbar
									:settings="perfectScrollbarSettings"
								>		
									<draggable
										:list="questionsList"
										:group="{name:'questions', pull:'clone', put:false }"
										class="list-group list-group-flush cursor-move"
									>										
										<b-list-group-item
											v-for="(item, index) in questionsList"
											:key="index"
											tag="li"
										>
											<div class="d-flex">
												<div class="ml-25">
													<b-card-text class="mb-0 font-weight-bold">
														{{`${index + 1}) ${item.question}`}}
													</b-card-text>
													<small>{{ item.type }}</small>
												</div>
											</div>
										</b-list-group-item>
									</draggable>							
								</perfect-scrollbar>
							</b-col>

							<!-- Preguntas para el examen -->
							<b-col
								md="12"
								class="mt-4"
							>
								<b-row>
									<b-col cols="9">
										<h6 class="text-primary font-weight-bold mb-2">
											Preguntas del Examen
										</h6>	
									</b-col>
									<b-col cols="3">
										<h6 class="text-primary font-weight-bold mb-2">
											Puntaje
										</h6>	
									</b-col>
									<b-col cols="12">
										<perfect-scrollbar
											:settings="perfectScrollbarSettings"
										>	
											<draggable
												:list="questionsExamList"
												:group="{name: 'questions'}"
												class="list-group list-group-flush cursor-move"
											>
												<template v-if="questionsExamList.length == 0 ">
													<!-- info -->
													<b-alert
														variant="primary"
														show
													>
														<h4 class="alert-heading">
															Info
														</h4>
														<div class="alert-body">
															<span>¡Sin preguntas! Arrastre las preguntas del banco</span>
														</div>
													</b-alert>
												</template>
												<template v-else>
													<b-list-group-item
														v-for="(item, index) in questionsExamList"
														:key="index"
														tag="li"
													>
														<b-row>
															<b-col sm="9">
																<b-card-text class="mb-0 font-weight-bold">
																{{`${index + 1}) ${item.question}`}}
																</b-card-text>
																<small>{{ item.type }}</small>	
															</b-col>
															<b-col sm="2"  class="my-auto">																
																<validation-provider
																	#default="{ errors }"
																	name="puntaje"
																	rules="required|min:0"
																>
																	<b-input-group>
																		<b-form-input 
																			v-model="item.score"
																			placeholder="Ingrese el puntaje"
																			type="number"
																			:state="errors.length > 0 ? false:null"
																			trim
																		/>
																	</b-input-group>                                             
																	<small class="text-danger">{{ errors[0] }}</small>                                         																
																</validation-provider>					
															</b-col>
															<!-- Remove Button -->
															<b-col sm="1" class="my-auto">
																<b-button				
																	v-ripple.400="'rgba(234, 84, 85, 0.15)'"
																	v-b-tooltip.hover.v-danger
																	variant="outline-danger"
																	title="Eliminar"													
																	@click="removeQuestion(index, item.id)"
																>
																	<feather-icon
																		icon="TrashIcon"                             
																	/>
																</b-button>
															</b-col>													
														</b-row>
													</b-list-group-item>
												</template>
											</draggable>
										</perfect-scrollbar>										
									</b-col>
								</b-row>
							</b-col>
						</b-row>        
					</b-form>    
				</b-col>        
			</b-row>
			<b-button
				variant="primary"
				class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
				@click="addExam"
				:block="$store.getters['app/currentBreakPoint'] === 'xs'"
			>
				Agregar Examen
			</b-button> 
		</validation-observer>
	</b-card-code>
</template>

<script>
require('@core/assets/fonts/feather/iconfont.css')
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BListGroupItem, BAvatar, BRow, BCol, BCardText, BForm, BFormTextarea, BFormCheckbox, BFormGroup, BFormInput, BButton, 
BInputGroup, BInputGroupPrepend, BInputGroupAppend, BAlert, VBTooltip } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import { required,between, min} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { PerfectScrollbar }from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
	components: {
		BButton,
		BInputGroupPrepend,
		BInputGroupAppend,
		BForm, 
		BFormTextarea, 
		BFormCheckbox, 
		BFormGroup, 
		BFormInput, 
		BInputGroup,
		BAvatar,
		BCardCode,
		BListGroupItem,
		BCardText,
		BRow,
		BCol,
		Prism,
		draggable,
		PerfectScrollbar,
		BAlert,
		
		ValidationProvider,
		ValidationObserver
	},
	directives: {
		'b-tooltip': VBTooltip,
		Ripple,
	},
	data() {
		return {		
			required,
			min,
			between,
			
			questionsList: [],
			questionsExamList: [],
			questionsExam: [],
			title: '',
			time: null,
			buscarPregunta: '',
			scoreTotal: 0,
			errorP1: 0,
			errorP2: 0,
		}
	},  
	watch: {
        questionsExamList: {    
			deep: true,
			handler(arrayChange){
				// ACTUALIZA PARA QUE NO SE REPITAN LAS PREGUNTAS EN LAS PREGUNTAS DEL EXAMEN			
				let itemRepeat = [];
				let itemsFound = {};				
				for(let i = 0, l = this.questionsExamList.length; i < l; i++) {
					let stringified = JSON.stringify(this.questionsExamList[i]);
					if(itemsFound[stringified]) { itemRepeat.push(i); continue; }
					itemsFound[stringified] = true;				 
				}									
				itemRepeat.map(item=> {
					this.questionsExamList.splice(item, 1);    
				});	
				
				// ACTUALIZA EL PUNTAJE TOTAL
				let sum = 0;	
				arrayChange.map(item => {
					if(item.score != ""){
						sum = sum + parseInt(item.score);
					}
					if(item.score === ""){
						this.errorP1 = 1;
					} else { 
						this.errorP1 = 0; 
					}	
					if(item.score < 0){
						this.errorP2 = 1;
					} else { 
						this.errorP2 = 0; 
					}					
				});
				
				// ALERTA SI SE EXCEDE DE 20
				this.scoreTotal = sum;			
				if(this.scoreTotal > 20){
					this.alertScore();
				}
			}
        },
    },
	mounted (){		
		this.searchQuestion();		
	},
	methods: {
		
		alertScore(){			
			Swal.fire({
				title: 'Info!',
				text: 'El puntaje total es superior a 20!',
				icon: 'info',
				customClass: {
					confirmButton: 'btn btn-primary',
				},
				buttonsStyling: false,
			})
		},			
		questionsShow (questions){			
			this.questionsList = [];		  
			questions.map(item => {
				// CONDICIONAL PROVISIONAL PARA QUE NO MUESTRE PREGUNTAS CON RESPUESTAS VACIAS
				if(item.answers.length > 0){
					this.questionsList.push({
						id: item.id,
						question: item.question,
						type: item.question_type.name,
						score: "",
					});	
				}
			});     		
		},
		addExam(){			
			let error, errorE0, errorE1, errorE2, errorE3 = 0;
			if(this.title === '' || this.time === null){ error = 1; }
			if(this.scoreTotal < 0) { errorE0 = 1; }
			if(this.time <= 0){ errorE1 = 1; }
			if(this.questionsExamList.length == 0){ errorE2 = 1; }	
			
			if(error){
				errorE3 = 1;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Debe completar la información de los campos correspondientes.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					}
				});
			}
			
			if(errorE0){
				errorE3 = 1;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'El puntaje total del examen no puede ser menor a 0.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					}
				});
			}
			
			if(errorE1){
				errorE3 = 1;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'El tiempo ingresado debe ser mayor a 0.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					}
				});
			}
			if(errorE2){
				errorE3 = 1;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'El examen debe tener al menos una pregunta.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					}
				});
			}
			
			if(this.errorP1){
				errorE3 = 1;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Cada pregunta debe tener su puntaje.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					}
				});
			}
			
			if(this.errorP2){
				errorE3 = 1;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'El puntaje no debe ser menor a 0.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					}
				});
			}
			
			if(!errorE3){
				var uniquesQuestions = [];
				var itemsFound = {};
				for(var i = 0, l = this.questionsExamList.length; i < l; i++) {
					var stringified = JSON.stringify(this.questionsExamList[i]);
					if(itemsFound[stringified]) { continue; }
					uniquesQuestions.push(this.questionsExamList[i]);
					itemsFound[stringified] = true;
				}
				 
				let questionsExamNew = [];
				uniquesQuestions.map((item) => {
					questionsExamNew.push({
						id: item.id,
						score: parseInt(item.score),
					})
				});
				
				let examData = {
					moduleId : router.currentRoute.params.id,
					title: this.title,
					// score: this.scoreTotal,
					time: this.time,
					questionsExamNew
				}                    
				axios.post(`${process.env.VUE_APP_HOST}/api/dashboard/course/module/exam/create`, examData)
				.then(response => {                                           
					this.$toast({
						component: ToastificationContent,
						props: {
							title: response.data.message,
							icon: 'CheckIcon',
							variant: 'success',
						},
					});    
					router.go(-1)                      
				})
				.catch( (err) => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Error al crear el Examen.',
							icon: 'AlertTriangleIcon',
							variant: 'danger'
						}
					});
				});  
			}
		},
		removeQuestion(index, id){ 
			
			this.questionsExamList.splice(index, 1);
			this.questionsList.map( (item,index) =>{
				if(item.id == id){
					this.questionsList[index].score = "";
				}
			});
		},
		searchQuestion(){
			axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/questions/select?question=${this.buscarPregunta}`)
			.then(response => {						
				this.questionsList = response.data;
			});
					
			setTimeout(() => {
				this.questionsShow(this.questionsList);
			}, 500);
		},
	},
	setup() {
		const perfectScrollbarSettings = {
      		maxScrollbarLength: 150,
		}				
		return {
			perfectScrollbarSettings,				
		}
    }
}
</script>

<style lang="scss">
	.ps {
		height: 300px;
	}
	.icon-code{
		display: none;
	}
</style>
<style lang="scss">
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

